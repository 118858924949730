.container {
  display: flex;
}

.circle {
  border: "2px solid black";
  border-radius: "25px";
  font-size: "1 rem";
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.gradient-text {
  background: -webkit-linear-gradient(135deg, rgb(62, 228, 237), rgb(18, 89, 231)); /* Example gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .summaryLine {
  margin-bottom: 0.5rem; 
  text-align: justify; 
} */

.summary {
  font-size: 1.1rem; /* Adjust the font size as needed */
  line-height: 1.6; /* Adjust line height for better readability */
  color: #333; /* Dark grey for better readability */
  margin: 1rem 0; /* Adjust top and bottom margin */
  font-family: 'Roboto', sans-serif; /* Choose a clean, modern font */
}

.summaryText {
  margin-bottom: 1rem; /* Space between paragraph lines */
  text-align: justify; /* Justify the text for a cleaner look */
  font-weight: bold; /* Make the summary text bold */
  color: #222; /* Adjust the text color for better visibility */
  font-size: 1.2rem; /* Slightly larger font size */
  line-height: 1.8; /* Increase line height for better readability */
  margin-top: 0.5rem; /* Space above each summary line */
  margin-bottom: 0.5rem; /* Space below each summary line */
  font-weight: bold; /* Make the name bold */
}

h1 span {
  font-weight: bold; /* Make the name bold */
}

h2 {
  margin-bottom: 1rem; /* Space between h2 and summary */
  font-size: 1.3rem; /* Slightly increase font size */
}

